import React from 'react';

import CheckoutPage from '../components/checkout/CheckoutPage';
import SearchEngineOptimization from '../components/utility/seo';
import { CheckoutContextProvider } from '../context/checkoutContext';

const Checkout = () => {
  return (
    <div>
      <SearchEngineOptimization title="Checkout" />
      <CheckoutContextProvider>
        <CheckoutPage />
      </CheckoutContextProvider>
    </div>
  );
};

export default Checkout;
