import { AddressPayload } from '@medusajs/medusa';
import { pick } from 'lodash';
import { useContext, useEffect } from 'react';

import { CheckoutContext } from '../context/checkoutContext';
import { useCustomer } from './use-customer';

const useCheckout = () => {
  const context = useContext(CheckoutContext);
  const {
    customer,
    actions: { addShippingAddress },
  } = useCustomer();

  const addresses = customer?.shipping_addresses || [];

  useEffect(() => {
    const defaultAddress = addresses.find(
      (address) => !!address.metadata.isDefault
    );
    defaultAddress && context?.setSelectedShippingAddress(defaultAddress);
  }, []);

  if (!context) {
    throw new Error(
      'useCheckout must be used within a CheckoutContextProvider'
    );
  }

  const processShippingAddress = async () => {
    const selectedShippingAddress = context.selectedShippingAddress;

    if (addresses.length === 0) {
      // Process values from the new address form
      try {
        await context.newAddressForm.validateFields();

        const { save_address, ...formValues } =
          context.newAddressForm.getFieldsValue();
        if (save_address) {
          await addShippingAddress(
            {
              ...formValues,
              city: formValues.province,
              metadata: {},
            },
            true
          );
        }

        return formValues as AddressPayload;
      } catch (error) {
        return null;
      }
    }

    if (!!selectedShippingAddress) {
      return pick(selectedShippingAddress, [
        'first_name',
        'last_name',
        'company',
        'address_1',
        'address_2',
        'city',
        'country_code',
        'province',
        'postal_code',
        'phone',
        'metadata',
      ]) as AddressPayload;
    }

    return null;
  };

  return {
    selectedShippingAddress: context.selectedShippingAddress,
    setSelectedShippingAddress: context.setSelectedShippingAddress,
    newAddressForm: context.newAddressForm,
    addresses,
    processShippingAddress,
  };
};

export default useCheckout;
