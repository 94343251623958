import { Checkbox, Form } from 'antd';
import React from 'react';

import useCheckout from '../../hooks/useCheckout';
import AddressForm from '../account/address/AddressForm';
import Card, { CardBody, CardHeader } from '../ui/Card';
import DeliveryAddresOptionList from './DeliveryAddressOptionList';

const DeliveryAddressSection = () => {
  const { newAddressForm, addresses } = useCheckout();

  return (
    <Card>
      <CardHeader>Delivery Address</CardHeader>
      <CardBody>
        {addresses.length > 0 ? (
          <DeliveryAddresOptionList />
        ) : (
          <div className="-mb-2">
            <Form form={newAddressForm} layout="vertical" noValidate>
              <AddressForm hideDefaultOption />
              <Form.Item
                initialValue={true}
                name="save_address"
                valuePropName="checked"
              >
                <Checkbox>Save this address for next time</Checkbox>
              </Form.Item>
            </Form>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default DeliveryAddressSection;
