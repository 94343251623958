import { Alert, Button, List, Typography } from 'antd';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import { instanceOf } from 'prop-types';
import React, { useState } from 'react';

import tailwindConfig from '../../../tailwind.config';
import { useCart } from '../../hooks/use-cart';
import useCheckout from '../../hooks/useCheckout';
import { formatPrice } from '../../utils/format-price';
import AccountDetail from '../account/AccountDetail';
import { ICartItem } from '../cart/CartItem';
import { CartProductDetail } from '../cart/CartProductDetail';
import MainLayout from '../layout/MainLayout';
import DeliveryAddressSection from './DeliveryAddressSection';
import OrderSummary from './OrderSummary';

const resolveConfig = require('tailwindcss/resolveConfig');
const config = resolveConfig(tailwindConfig);

const CheckoutPage = () => {
  return (
    <MainLayout logoOnly>
      <div className="mx-auto -mt-10 flex max-w-[1130px] flex-col p-6 pt-0 lg:flex-row">
        <div className="flex flex-1 flex-col gap-3">
          <AccountDetail editable={false} />
          <DeliveryAddressSection />
        </div>
        <div className="ml-0 mt-6 h-fit w-full shrink-0 grow-0 overflow-hidden rounded-md border border-light-divider bg-neutral-1 shadow-small lg:mt-0 lg:ml-6 lg:basis-[440px]">
          <OrderSummary />
        </div>
      </div>
    </MainLayout>
  );
};

export default CheckoutPage;
