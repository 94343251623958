import { Address } from '@medusajs/medusa';
import { Form, FormInstance } from 'antd';
import React, { ReactNode, createContext, useState } from 'react';

import { AddressFormSchema } from '../components/account/address/AddressForm';

interface NewAddressFormSchema extends AddressFormSchema {
  save_address: boolean;
}

interface CheckoutContextType {
  selectedShippingAddress: Address | null;
  setSelectedShippingAddress: (address: Address) => void;
  newAddressForm: FormInstance<NewAddressFormSchema>;
}

export const CheckoutContext = createContext<CheckoutContextType | undefined>(
  undefined
);

export const CheckoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [newAddressForm] = Form.useForm<NewAddressFormSchema>();

  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState<Address | null>(null);

  return (
    <CheckoutContext.Provider
      value={{
        selectedShippingAddress,
        setSelectedShippingAddress,
        newAddressForm,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
